import Cookies from "js-cookie";
import {customEvent} from "@songfinch/shared/plugins/sf_analytics";

export function setTemporaryEmail(email: string) {
    Cookies.set("sf_temporary_email", email, {expires: 360});
    if (email) {
        customEvent("_sf_identify_user", {email});
    }
}

export function getTemporaryEmail() {
    return Cookies.get("sf_temporary_email");
}
