import axios from "axios";
import store from "../store";
import router from "../router";

//Cache
import {setupCache} from "axios-cache-adapter";
import RefreshPopup from "../components/popups/RefreshPopup";

//Default for Api
axios.defaults.baseURL = window.location.origin + "/v3/api";
axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector('meta[name="csrf-token"]')?.content;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["SF-FE-App-Name"] = "customer";
axios.defaults.headers.common["SF-FE-App-V"] = window.appSettings.version;
axios.defaults.headers.common["SF-FE-Data-V"] = window.appSettings.data_version;

const cache = setupCache({
    maxAge: 15 * 60 * 1000,
    exclude: {
        query: false,
    },
    ignoreCache: true,
});
axios.defaults.adapter = cache.adapter;

//After ech request
axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    //store.commit("loader/setLoader", false);
    return Promise.reject(error);
});


//After each response
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const res = error?.response;
    //503 Error maintenance (Heroku on maintaince)
    if (res?.status === 503) {
        location.reload();
        //426 Error Outdated App
    } else if (res?.status === 426 && res?.data?.sf_refresh) {
        window.appSettings.sentry_dns = null; // Clearing to stop any sentry errors
        const clearData = res.data.sf_clear_data;
        //Regular reload when switching pages && we don't need to clear data
        if (res.config.cmsCall && !clearData) {
            location.reload();
            store.commit("shared/setReloadingApp", true);
        } else if (store.state.shared.modalComponent?.component !== RefreshPopup && !store.state.shared.reloadingApp) {
            store.commit("shared/showModal", {
                component: RefreshPopup,
                props: {showClearMessage: !!clearData},
                swalSettings: {showCloseButton: false},
            });
        }
        return Promise.resolve({});
        //422 Error invalid token we also returning user with that
    } else if (res?.status === 422 && res.data.invalid_token && !res.config.sf_repeat) {
        res.config.headers["X-CSRF-Token"] = res.headers["x-csrf-token"];
        res.config.sf_repeat = true;
        store.commit("auth/setUser", res.data.user);
        store.commit("auth/setResponseToken", res);
        return axios.request(res.config);
    } else if (res?.status === 401 && store.state.auth.user) {
        location.href = `/login?push_to_path=${router.currentRoute.value.path}`;
    }

    return Promise.reject(error);
    //store.commit("loader/setLoader", false);
});
