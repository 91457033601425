import app from "@songfinch/customer/app.js";

import Slider from "@songfinch/shared/plugins/slider";
app.use(Slider);

import AudioPlayerPlugin from "@songfinch/shared/plugins/audio_player";
app.use(AudioPlayerPlugin);

import Flags from "@songfinch/shared/plugins/flags";
app.use(Flags);

import PrimeVue from "primevue/config";
//import "primevue/resources/primevue.min.css";
app.use(PrimeVue);

import Modal from "@songfinch/shared/plugins/modal";
app.use(Modal, {
    customClass: {
        confirmButton: "mkButton @mx-2.5 @mb-5 noLoading",
        cancelButton: "mkButton @mx-2.5 dark @mb-5 noLoading",
    },
    showClass: {
        popup: "fadeIn"
    },
    hideClass: {
        popup: "fadeOut",
    },
    width: 575,
    background: "var(--sfc-beige)",
    iconColor: "var(--sfc-red)",
    buttonsStyling: false
});
