<template>
    <footer v-if="data" class="@bg-sfc-black @text-sfc-white @pt-16 desktop:@pt-20 @pb-12 desktop:@pb-10">
        <div class="@container @px-4">
            <div class="@grid mobile:@grid-cols-2 mobile:@gap-x-8 tablet:@grid-cols-4 tablet:@gap-x-5 desktop:@grid-cols-6 colsList">
                <div v-for="(column, i) in data.menu" :key="i" class="@mb-8">
                    <div class="l2 @mb-4">
                        {{column.title}}
                    </div>
                    <a v-for="(link, index) in column.links" :key="index" class="p3 @flex @mb-4" :href="link.link" :aria-label="link.label">{{link.label}}</a>
                </div>
                <div class="mobile:@col-start-1 mobile:@col-end-3 tablet:@col-start-1 tablet:@col-end-3 desktop:@col-start-5 desktop:@col-end-7">
                    <form class="form-group @mb-6 @max-w-375 @mx-0" :class="{loading, isSubmitted}" @submit.prevent="sendEmail">
                        <label for="mailing_list" class="l2 @block @mb-6">
                            JOIN OUR MAILING LIST
                        </label>
                        <div class="@relative">
                            <input
                                id="mailing_list"
                                v-model="email"
                                v-email-validity
                                type="email"
                                required
                                class="form-control @w-full !@mt-0 !@border-0"
                                placeholder="Email"
                            >
                            <button
                                aria-label="Submit email"
                                @click="isSubmitted = true"
                            >
                                <div
                                    class="masked !@mt-0 @w-full @h-full @bg-sfc-black"
                                    :style="{maskImage: `url('${arrowUrl}')`}"
                                />
                            </button>
                        </div>
                    </form>
                    <SlideUpDown :model-value="error" class="text-danger @text-start"/>
                    <SlideUpDown :model-value="emailSent" class="l6">
                        Success!
                    </SlideUpDown>
                    <div class="socials @mt-6">
                        <a
                            v-for="(slink, i) in data.social_links"
                            :key="i"
                            :aria-label="`Link to Songfinch's ${slink.name}`"
                            target="_blank"
                            :href="slink.link"
                        >
                            <div class="@mr-5 masked @inline-block" :style="{maskImage: $cld.bgUrl(slink.icon)}">
                                <img :src="$cld.url(slink.icon)" class="@invisible" width="28" height="28" :alt="`footer ${slink.name}`">
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="@grid tablet:@grid-cols-6 tablet:@gap-x-5 desktop:@items-center @mt-16 desktop:@mt-12 @pt-2">
                <div class="@mb-6 desktop:@mb-0">
                    <router-link :to="{name: 'Home'}" aria-label="home page">
                        <Logo fill="var(--sfc-white)" width="145" height="39"/>
                    </router-link>
                </div>
                <div class="p3 desktop:@col-start-2 desktop:@col-end-4 @col-start-1 @col-end-3">
                    Copyright © {{new Date().getFullYear()}} all rights reserved
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import Logo from "../../assets/images/Logo";
    import axios from "axios";
    import error_handler from "@songfinch/shared/helpers/axios_error_handler";
    import arrowUrl from "@songfinch/customer/assets/images/arrow-right.svg";
    import SlideUpDown from "@songfinch/shared/components/SlideUpDown";
    import {emailValidity} from "@songfinch/shared/mixins/validity_directives";

    export default {
        name: "FooterCustomer",
        directives: {emailValidity},
        components: {Logo, SlideUpDown},
        data() {
            return {
                loading: false,
                arrowUrl,
                email: "",
                emailSent: false,
                isSubmitted: false,
                error: "",
            };
        },
        computed: {
            data() {
                return this.$store.state.cms.footer;
            }
        },
        methods: {
            async sendEmail() {
                if (this.loading) return;
                this.loading = true;
                this.error = "";
                try {
                    await axios.post("/forms/signup", {email: this.email});
                    this.email = "";
                    this.emailSent = true;
                    this.$customEvent("_sf_footer_conversion");
                    this.$store.commit("shared/setHasInteractedWithSongfinch");
                } catch (e) {
                    this.error = error_handler(e).error;
                }
                this.loading = false;
            }
        }
    };
</script>

<style scoped>
    footer {
        transition: all 0.5s;
        max-height: 2000px;
        overflow: hidden;
        background-color: var(--sfc-black);
        color: var(--sfc-white);

        form {
            @screen tablet-only {
                max-width: 325px;
            }

            button {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                color: transparent;
                background-color: transparent;
                width: 18px;
                height: 14px;

                div {
                    transition: all 0.3s;
                    &:hover {
                        background-color: var(--sfc-red);
                    }
                }
            }

            &.loading input[type="submit"] {
                display: none;
            }
        }

        .socials {
            a > div {
                background-color: var(--sfc-white);
                transition: all 0.3s;

                img {
                    max-width: 28px;
                }

                &:hover {
                    background-color: var(--sfc-red);
                }
            }
        }
    }
</style>
