<template>
    <div class="@text-center @px-5 tablet:@px-8 @pt-10 tablet:@pt-12 @pb-10 tablet:@pb-14 cmsContent">
        <div v-if="content" v-html="content"/>
        <div>
            <div class="mkButton fullMob @mt-4" @click="refresh">
                Refresh
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RefreshPopup",
        props: {
            showClearMessage: Boolean
        },
        data() {
            return {
                seconds: 10,
                interval: null,
                content: null,
            };
        },
        mounted() {
            this.seconds = +window.appSettings.refresh_popup?.seconds || 10;
            this.content = this.showClearMessage ? window.appSettings.refresh_popup?.clear_data_message : window.appSettings.refresh_popup?.content;
            this.interval = setTimeout(() => {
                this.refresh();
            }, this.seconds * 1000);
        },
        beforeUnmount() {
            this.refresh();
        },
        methods: {
            refresh() {
                clearTimeout(this.interval);
                if (this.showClearMessage) {
                    this.$store.commit("shared/clearLocalStorage");
                }
                location.reload();
            }
        }
    };
</script>

<style>
    @import "@songfinch/customer/stylesheets/includes/pages/_cms-content.pcss";
</style>
