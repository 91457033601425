import axios from "axios";
import {omit, pick, cloneDeep, isEqual} from "lodash-es";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {resetInstantData} from "../composables/useInstantProductBuilder";

import store from "@songfinch/customer/store";
import router from "@songfinch/customer/router";
import app from "@songfinch/customer/app";
import {computed} from "vue";
import {setProductForAdditionalInfo, loadSingleProduct, isInstantProduct} from "@songfinch/customer/composables/useProduct";
import uuid from "@songfinch/shared/helpers/uuid";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
import get_error from "@songfinch/shared/helpers/get_error.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    namespaced: true,
    state: {
        cart: {},
    },
    mutations: {
        setStandardDeliveryDays(state, val) {
            if (!isEqual(state.cart.standardDeliveryDays, val)) {
                state.cart.standardDeliveryDays = val;
                this.commit("cart/saveCart");
            }
        },
        setTaxes(state, val) {
            state.cart.taxes = val;
            this.commit("cart/saveCart");
        },
        setReservedCoupon(state, val) {
            if (val) {
                state.cart.reservedCoupon = {
                    code: val.code,
                    products: val.only_with,
                    products_any: val.only_with_any
                };
            } else {
                state.cart.reservedCoupon = null;
            }

            this.commit("cart/saveCart");
        },
        setCoupon(state, val) {
            state.cart.coupon = val;
            this.commit("cart/saveCart");
        },
        setStripeClientSecret(state, val) {
            state.cart.stripe_client_secret = val;
            this.commit("cart/saveCart");
        },
        setAddress(state, val) {
            const key = val.shipping ? "shipping" : "billing";
            if (!isEqual(state.cart.addresses[key], val.address)) {
                state.cart.addresses[key] = val.address;
                this.commit("cart/saveCart");
            }
        },
        resetAddresses(state) {
            state.cart.addresses = {shipping: null, billing: null};
            this.commit("cart/saveCart");
        },
        saveCart(state) {
            state.cart.calcTotals();
            localStorage.setItem("sf_cart", JSON.stringify(
                omit(state.cart, ["cartProductsArray"])
            ));
        },
        resetCart(state, saveCart) {
            state.cart.products = [];
            state.cart.standardDeliveryDays = null;
            state.cart.coupon = null;
            state.cart.payment_type = null;
            state.cart.addresses = {shipping: null, billing: null};
            state.cart.taxes = 0;
            state.cart.subtotal = 0;
            state.cart.discount = 0;
            state.cart.credit_applied = 0;
            state.cart.total = 0;
            state.cart.reservedCoupon = null; //Coupon from URL query
            state.cart.affiliate = null;
            state.cart.stripe_client_secret = "";
            state.cart.payment_type = "card";
            saveCart && this.commit("cart/saveCart");
        },
        initCart(state) {
            const savedCart = localStorage.getItem("sf_cart");
            this.commit("cart/resetCart");
            if (savedCart) {
                state.cart = {...state.cart, ...JSON.parse(savedCart)};
            }
            state.cart.findDiscountableProductByName = findDiscountableProductByName.bind(state.cart);
            state.cart.hasAllProductsForCoupon = hasAllProductsForCoupon.bind(state.cart);
            state.cart.hasAnyProductsForCoupon = hasAnyProductsForCoupon.bind(state.cart);
            state.cart.calcTotals = calcTotals.bind(state.cart);
            state.cart.length = length.bind(state.cart);
            state.cart.hasType = hasType.bind(state.cart);
            state.cart.requireShippingAddress = requireShippingAddress.bind(state.cart);
            state.cart.isInternationalShippingAllowed = isInternationalShippingAllowed.bind(state.cart);
            state.cart.generateData = generateData.bind(state.cart);
            state.cart.frontendValidation = frontendValidation.bind(state.cart);
            state.cart.findProductByName = findProductByName.bind(state.cart);
            state.cart.findProductIndexByName = findProductIndexByName.bind(state.cart);
            state.cart.isDiscountable = isDiscountable.bind(state.cart);
            state.cart.getRushFee = getRushFee.bind(state.cart);

            state.cart.hasOriginalSongRevision = computed(() => state.cart.findProductByName("revision-request"));
            state.cart.hasOriginalSong = computed(() => state.cart.findProductByName("personalized-song"));
            state.cart.hasInstantSong = computed(() => state.cart.products.find(isInstantProduct));
            state.cart.cartProductsArray = computed(() => state.cart.products.filter(p => p.name !== "service-fee"));
        },
        setSongDeliveryType(state, type) {
            this.state.songBuilder.songData.deliveryType = type;
        },
        setPaymentType(state, val) {
            state.cart.payment_type = val;
            this.commit("cart/saveCart");
        },
        updateCart(state, {product, index}) {
            state.cart.products[index] = product;
            this.commit("cart/saveCart");
        }
    },
    actions: {
        async addProduct({state, commit}, {product, skipToast, dontShowDetailModal, ignoreSegmenetEvent} = {}) {

            if (!product) {
                $toastMsg("Error product is missing. Please contact us at support@songfinch.com");
                return false;
            }

            product = await setProductForAdditionalInfo(product, dontShowDetailModal);
            if (!product) return;

            product.quantity ||= 1;
            const isOriginalSong = ["personalized-song"].includes(product.name);
            const isInstantSong = isInstantProduct(product);
            const isSong = isOriginalSong || isInstantSong;

            product.cart_uuid = uuid();

            if (isSong) {
                if (state.cart.hasOriginalSong || state.cart.hasInstantSong) {
                    const res = await this.dispatch("shared/confirmationModal", "You already have a song in your cart. Adding a new one will reset your current cart. Are you sure you want to proceed?");
                    if (!res) return false;
                    // Remove instant data if replacing cart with original song
                    if (isOriginalSong) {
                        resetInstantData();
                    } else {
                        this.commit("songBuilder/resetSongData");
                    }
                    commit("resetCart");
                }
                //If it song it goes first
                state.cart.products.unshift(product);
            } else {
                //if it's addon insert right after song else next availabe
                const insertIndex = product.underSong ? 1 : state.cart.products.length;
                state.cart.products.splice(insertIndex, 0, product);
            }

            this.commit("cart/saveCart");
            if (!ignoreSegmenetEvent) {
                app.config.globalProperties.$customEvent("_sf_add_to_cart", {
                    product: pick(product, ["id", "name", "title", "sku", "price", "quantity", "product_type", "cta_clicked"])
                });
            }

            this.dispatch("cart/checkReservedCoupon");

            if (!skipToast) {
                const toast = `${product.title} added to cart <div class="@mt-1 @pb-2 l5 @underline" onclick="window.sfPushToPage('/cart')">go to cart</div>`;
                $toastMsg(toast);
            }

            await this.dispatch("cart/checkForAutoPromoAddons", product);
            return true;
        },
        async checkForAutoPromoAddons(_, product) {
            if (!product.extra_info?.promo_addons?.length) return;
            for (const promoAddon of product.extra_info.promo_addons) {
                if (promoAddon.auto_add_to_cart === "1" && isPromoAddonValid(promoAddon)) {
                    const loadedProduct = await loadSingleProduct({productSlug: promoAddon.product_name});
                    loadedProduct.parent_story_id = product.parent_story_id;
                    loadedProduct.owner_id = product.owner_id;
                    if (!loadedProduct) continue;
                    await this.dispatch("cart/addProduct", {product: loadedProduct, dontShowDetailModal: true, skipToast: true});
                }
            }
        },
        async checkReservedCoupon({dispatch, state}) {
            const cart = state.cart;
            const reservedCoupon = cart.reservedCoupon;
            if (!reservedCoupon || cart.coupon || !cart.products.length) return;
            if (!cart.hasAllProductsForCoupon(reservedCoupon.products)) return;
            if (!cart.hasAnyProductsForCoupon(reservedCoupon.products_any)) return;
            dispatch("loadCoupon", reservedCoupon.code).catch(() => null);
        },
        async loadCoupon({commit, state}, coupon) {
            const res = await axios.get("store/apply_coupon", {params: {coupon}});
            const cpn = res.data.coupon;
            if (!state.cart.hasAllProductsForCoupon(cpn.only_with) || !state.cart.hasAnyProductsForCoupon(cpn.only_with_any) || !state.cart.isDiscountable()) {
                commit("setReservedCoupon", cpn);
                throw new Error("Sorry, this coupon is not applicable to the products.");
            }
            commit("setCoupon", cpn);
            return cpn;
        },
        async validateProducts({state, commit}) {
            if (!state.cart.length()) return;
            const ids = [...new Set(state.cart.products.map(p => p.id))]; //unique
            const res = await axios.get("store/products", {params: {ids}});
            let hasUpdated = false;
            state.cart.products = state.cart.products.filter(p => {
                if (p.parent_story_id && p.price === 0 && p.name === "personalized-song") return true;
                const dbProduct = res.data.find(sp => sp.id === p.id);
                if (!dbProduct) {
                    hasUpdated = true;
                    return false;
                }

                // All bundled has the same products bundled_products
                if (p.product_type === "bundle"
                    && (dbProduct.bundled_products.length !== p.bundled_products?.length
                        || !p.bundled_products?.every(i => dbProduct.bundled_products.find(dp => dp.name === i.name)))
                ) {
                    hasUpdated = true;
                    return false;
                }

                const valuesToCompare = ["price", "extra_info"];
                const a = pick(p, valuesToCompare);
                const b = pick(dbProduct, valuesToCompare);

                const productsToSkipValidation = ["artist-gratuity", "personalized-song-rush-fee-v2", "revision-request"];

                if (!productsToSkipValidation.includes(p.name) && !isEqual(a, b)) {
                    hasUpdated = true;
                    valuesToCompare.forEach(k => p[k] = dbProduct[k]);
                }
                return true;
            });
            if (hasUpdated) commit("saveCart");
        },
        async validateAndGetPaymentSettings({state, commit}) {
            try {
                commit("setTaxes", 0);
                const data = await state.cart.generateData();
                commit("setStripeClientSecret", "");
                const res = await axios.post("checkout/payment_settings", data);
                commit("setTaxes", res.data.taxes);
                commit("setStripeClientSecret", res.data.stripe_client_secret);
                return res.data;
            } catch (e) {
                return checkoutErrorHandler(e, true);
            }
        },
        async validateCoupon({state}) {
            try {
                const data = await state.cart.generateData();
                await axios.post("checkout/coupon_validation", data);
            } catch (e) {
                return checkoutErrorHandler(e, true);
            }
        },
        async submit({state}, {paypal_info} = {}) {
            if (!store.state.auth.user) {
                return router.push({name: "Login", query: {skipRedirect: true}});
            }

            if (!await state.cart.frontendValidation()) return; //If validation failed redirect to cart

            try {
                const data = await state.cart.generateData();
                data.submission_id = localStorage.getItem("sf_submission_id") || null;
                data.paypal_info = paypal_info;
                if (data.submission_id === "undefined") data.submission_id = null;
                const res = await axios.post("checkout/submit", data);
                localStorage.setItem("sf_submission_id", res.data.submission_id);
                return res.data;
            } catch (e) {
                return checkoutErrorHandler(e);
            }
        },
        async initAffiliate({state}) {
            await router.isReady();
            if (router.currentRoute.value.query.affiliate) {
                state.cart.affiliate = router.currentRoute.value.query.affiliate;
                this.commit("cart/saveCart");
            }
        },
        removeRushFee({state, dispatch}) {
            const index = state.cart.products.findIndex(p => p.name === "personalized-song-rush-fee-v2");
            if (index !== -1) {
                return dispatch("removeProduct", index);
            }
        },
        removeProduct({state, commit}, i) {
            const cart = state.cart;
            const [removed_product] = cart.products.splice(i, 1);
            if (removed_product.name === "american-greetings-e-card") {
                commit("setSongDeliveryType", "standard");
            } else if (removed_product.name === "personalized-song") {
                commit("resetCart", true);
                this.commit("songBuilder/resetSongData");
            } else if (isInstantProduct(removed_product)) {
                commit("resetCart", true);
                resetInstantData();
            }

            app.config.globalProperties.$customEvent("_sf_remove_from_cart", {
                product: pick(removed_product, ["id", "price", "title", "quantity", "sku"])
            });

            if (cart.coupon && (!cart.hasAllProductsForCoupon(cart.coupon?.only_with) || !cart.hasAnyProductsForCoupon(cart.coupon?.only_with_any) || !state.cart.isDiscountable())) {
                commit("setReservedCoupon", cart.coupon);
                commit("setCoupon", null);
            }
            commit("saveCart");
        }
    }
};


const findProductByName = function (name) {
    return this.products.find(p => p.name === name);
};

const findProductIndexByName = function (name) {
    return this.products.findIndex(p => p.name === name);
};

const findDiscountableProductByName = function (name) {
    return this.products.find(p => p.name === name && !p.exclude_from_discounts);
};

const hasAllProductsForCoupon = function (products) {
    if (!products?.length) return true;
    return products.every(this.findDiscountableProductByName);
};

const hasAnyProductsForCoupon = function (products) {
    if (!products?.length) return true;
    return products.some(this.findDiscountableProductByName);
};

const hasType = function (type) {
    return !!this.products.find(p => p.product_type === type);
};

const requireShippingAddress = function () {
    return !!this.products.find(p => p.requires_shipping_address);
};

const isInternationalShippingAllowed = function () {
    return !this.products.find(p => p.product_type === "physical" && !p.extra_info?.international_shipping);
};

const isDiscountable = function () {
    return !this.products.every(p => p.extra_info?.exclude_from_discounts);
};

const getRushFee = function () {
    return this.findProductByName("personalized-song-rush-fee-v2");
};

const calcTotals = function () {
    const user = store.state.auth.user;
    let discountLeft = this.coupon?.discount_fixed || 0;
    let subtotal = 0;
    let discount = 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let promoAddonsDiscount = 0;
    let creditApplied = 0;
    let total = 0;
    let discountAlreadyApplied = false;
    const userCredit = {
        taxable: (+user?.credit_amount_taxable || 0),
        nonTaxable: +user?.credit_amount || 0
    };

    const promoAddons = this.products.reduce((allProductPromos, p) => {
        if (p.extra_info?.promo_addons?.length) {
            p.extra_info.promo_addons.forEach(promoAddon => {
                if (isPromoAddonValid(promoAddon)) {
                    allProductPromos.push(promoAddon);
                }
            });
        }
        return allProductPromos;
    }, []);

    this.products.forEach(p => {
        let totalProductPrice = +p.price;

        if (promoAddons.length) {
            const promoAddonIndex = promoAddons.findIndex(b => b.product_name === p.name);
            if (promoAddonIndex !== -1) {
                let productDiscount = 0;
                const promoAddon = promoAddons[promoAddonIndex];
                if (+promoAddon.discount_percent) {
                    productDiscount = Math.ceil(totalProductPrice * +promoAddon.discount_percent / 100);
                } else {
                    productDiscount = Math.min(totalProductPrice, +promoAddon.discount_fixed);
                }
                productDiscount = Math.min(productDiscount, totalProductPrice);
                totalProductPrice -= productDiscount;
                promoAddonsDiscount += productDiscount;
                promoAddons.splice(promoAddonIndex, 1);
            }
        }

        p.cartPrice = totalProductPrice;

        // substract discount(coupon) from product price
        const isServiceFeeDiscountable = p.name === "service-fee" && this.coupon?.include_service_fee;
        const isCouponForProductOnly = p.name === this.coupon?.only_to_product;
        const isProductDiscountable = !p.extra_info?.exclude_from_discounts && !(this.coupon?.only_to_product && (!isCouponForProductOnly || discountAlreadyApplied));
        if (this.coupon && (isProductDiscountable || isServiceFeeDiscountable)) {
            let productDiscount = 0;
            if (this.coupon?.discount_percent) {
                productDiscount = Math.ceil(totalProductPrice * this.coupon?.discount_percent / 100);
            } else {
                productDiscount = Math.min(totalProductPrice, discountLeft);
                discountLeft -= productDiscount;
            }
            if (productDiscount && isCouponForProductOnly) discountAlreadyApplied = true;
            totalProductPrice -= productDiscount;
            discount += productDiscount;
        }

        // substract credit from price credit if not exclude_from_account_credits and the same owner
        if (!p.extra_info?.exclude_from_account_credits && (!p.owner_id || p.owner_id === user?.id)) {
            ["nonTaxable", "taxable"].forEach(k => {
                if (!userCredit[k] && !totalProductPrice) return;
                const productCredits = Math.min(totalProductPrice, userCredit[k]);
                userCredit[k] -= productCredits;
                totalProductPrice -= productCredits;
                creditApplied += productCredits;
            });
        }

        total += totalProductPrice;
    });

    this.cartProductsArray?.forEach(p => subtotal += +p.cartPrice);

    // Adding taxes
    total += this.taxes;

    // apply taxable credits to tax if any left
    if (this.taxes && userCredit.taxable) {
        const taxCredits = Math.min(total, userCredit.taxable);
        userCredit.taxable -= taxCredits;
        total -= taxCredits;
        creditApplied += taxCredits;
    }

    this.credit_applied = creditApplied;
    this.discount = discount;
    this.subtotal = subtotal;
    this.total = total < 50 ? 0 : total;
};


const length = function () {
    return this.cartProductsArray.length;
};

const generateData = async function () {
    const songData = cloneDeep(store.state.songBuilder.songData);
    songData.questions = songData.questions?.map(q => pick(q, ["answer", "key", "question"]));
    songData.must_have_questions = songData.must_have_questions?.map(q => {
        q.answer += "\r\n" + q.description;
        return q;
    });
    songData.gifter_email ||= store.state.auth.user.email;
    songData.selected_artists = songData.selected_artists?.filter(a => a).map(q => omit(q, ["genresOptions"]));
    const rushFeeInfo = this.getRushFee()?.rushFeeInfo || this.standardDeliveryDays;
    return {
        sf_song_data: songData,
        sf_cart: {
            addresses: this.addresses,
            cart_total: this.total,
            rush_fee: pick(rushFeeInfo, ["add_days", "cost", "holiday_skip"]),
            coupon_code: this.coupon?.code,
            affiliate: this.affiliate,
            products: this.products.map(p => {
                const allowedAttributes = ["id", "price", "name", "parent_story_id", "product_type", "additional_info", "cover_public_id",
                    "checkout_id", "instant_data", "bundled_products"];
                if (p.name === "revision-request" || p.extra_info.instant_product) {
                    allowedAttributes.push("extra_info");
                } else if (p.name === "personalized-song-rush-fee-v2") {
                    allowedAttributes.push("extra_info");
                    p = {...p, extra_info: pick(p.rushFeeInfo, ["add_days", "cost", "holiday_skip"])};
                }
                const productData = pick(p, allowedAttributes);
                productData.price = p.cartPrice;
                return productData;
            }),
            user_id: store.state.auth.user.id,
            payment_type: this.payment_type,
            stripe_client_secret: this.stripe_client_secret,
        },
    };
};

const frontendValidation = async function() {
    if (this.hasOriginalSong && !await store.dispatch("songBuilder/validateSongData")) {
        return false;
    }

    const productsOriginalLength = this.products.length;
    let callsResult = [];

    const calls = this.products.map(p => {
        if (p.cover_public_id) {
            return axios.head(app.config.globalProperties.$cld.url(p.cover_public_id));
        }
        return null; // if it's null Promise.allSettled return fulfilled
    });
    callsResult = await Promise.allSettled(calls);

    this.products = this.products.filter((p, i) => {
        if (callsResult[i]?.status === "rejected") return false; // remove product if async check return rejected

        // remove product if expired or it's bundle
        if (p.bundled_products?.find(bp => bp.expire && dayjs().isAfter(bp.expire))) return false;
        return !(p.expire && dayjs().isAfter(p.expire));
    });

    if (productsOriginalLength !== this.products.length) {
        checkoutErrorHandler({error: "Some items have expired and have been removed from the cart."}, true);
        return false;
    }
    return true;
};

function isPromoAddonValid(promoAddon) {
    if (!promoAddon.end_datetime && !promoAddon.start_datetime) return true;
    const startDate = promoAddon.start_datetime ? dayjs.tz(promoAddon.start_datetime, "America/Chicago") : dayjs.tz(new Date(), "America/Chicago").subtract(1, "day");
    const endDate = promoAddon.end_datetime ? dayjs.tz(promoAddon.end_datetime, "America/Chicago") : dayjs.tz(new Date(), "America/Chicago").add(1, "day");
    return dayjs.tz(new Date(), "America/Chicago").isBetween(startDate, endDate);
}

export function checkoutErrorHandler(error, pushToCart = false) {
    const errorMessage = get_error(error);
    const resData = error?.response?.data || {};

    if (resData.reset_addresses) {
        store.commit("cart/resetAddresses");
        pushToCart = true;
    }

    if (resData.reset_selected_artists) {
        router.push({name: "BsSongArtist", query: {artist_unavailabe: 1}});
        if (!pushToCart) throw new Error(errorMessage);
        return;
    }

    if (resData.reset_coupon) {
        store.commit("cart/setCoupon", null);
        store.commit("cart/setReservedCoupon", null);
    }

    if (resData.reset_rush_fee) {
        store.dispatch("cart/removeRushFee");
    }

    if (pushToCart) {
        app.config.globalProperties.$swal({
            icon: "warning",
            html: `<h3>${errorMessage}</h3>`,
        });
        return router.push({name: "Cart"});
    } else {
        throw new Error(errorMessage);
    }
}



