import app from "@songfinch/customer/app";
import {init, setTag} from "@sentry/vue";
import store from "@songfinch/customer/store";
import Cookies from "js-cookie";
import {merge, mapValues} from "lodash-es";
import useInstantProductBuilder from "@songfinch/customer/composables/useInstantProductBuilder";
import {toValue} from "vue";

if (window.appSettings.sentry_dns) {
    const js_hash = window.appSettings.version;
    init({
        app,
        dsn: window.appSettings.sentry_dns,
        //release: window.appSettings.release_version, //???
        normalizeDepth: 10,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: window.appSettings.env === "production" ? 1.0 : 0.25, // ???
        ignoreErrors: [
            /^AbortError:/,
            /^Illegal invocation/, //Some TikTok pixel error
            /^Ignore:/,
            /^Blocked a frame with origin/,
            /^ResizeObserver loop/,
            /^Request aborted/,
            /^Unable to preload CSS/,
            /AutoFillPopupClose/, // caused by Chrome's autofill
            /^Non-Error promise rejection captured/,
            /sentry-browser\.min\.js/ // excluding error everytime when zendesk can't load sentry-browser.min.js cuz of cache
        ],
        denyUrls: [
            /^https?:\/\/(.+\.)?userway\.org/
        ],
        beforeSend(event, hint) {
            const error = hint.originalException;
            if (error?.message?.match(/(Failed to fetch dynamically imported module|Importing a module script failed)/) && window.location.hash !== "#reloaded") {
                window.location.hash = "#reloaded";
                window.location.reload();
                window.appSettings.sentry_dns = null; // Clearing to stop any sentry errors
            }

            //TODO: add better error handling
            if (hint.captureContext?.extra?.sfErrorIgonre) return;

            if (!window.appSettings.sentry_dns) { //We can clear this value if we want to stop error tracking
                return null;
            }
            // UserID Resolution
            // * User (external user id)
            // * Cookie (segment user id)
            // * Cookie (segment anonymous user id)
            // * Cookie (CIO anonymous user id)
            const id = store.state.auth.user?.external_user_id ||
                Cookies.get("ajs_user_id") ||
                Cookies.get("ajs_anonymous_id") ||
                Cookies.get("_cioanonid");

            const extra = {
                cart: store.state.cart,
                song_builder: store.state.songBuilder,
                instant_builder: mapValues(useInstantProductBuilder(), v => (v ? toValue(v.value) : v)),
                js_hash,
            };

            if (error?.redirectsList?.length) {
                extra.redirectsList = error?.redirectsList;
            }

            merge(event, {extra});
            event.user = {id};
            return event;
        }
    });
    setTag("sf_pack", "de_purchase_first_v1");
    setTag("sf_origin", "frontend");
    setTag("js_hash", js_hash);
}

