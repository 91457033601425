import {ref, readonly} from "vue";

export type FlipperFeatureType = Partial<{[k:string]: boolean}>

const features = ref<FlipperFeatureType>(window.appSettings.features || {});
const value = ref(Object.keys(features.value).map((name) => `${name}_${features.value[name] ? "on" : "off"}`));

export default function useFlipper() {
    const initFlipper = () => {
        if (window.appSettings.env !== "production") {
            const params = new URLSearchParams(window.location.search);
    
            for (const key of params.keys()) {
                if (key.indexOf("feature_") === 0) {
                    const flag = key.slice("feature_".length);
                    const value = params.get(key) !== "false" && params.get(key) !== "0";
                    features.value[flag] = value;
                }
            }
        }
    };

    return {
        initFlipper,
        flipperFeatures: readonly(features),
        flipperValues: readonly(value),
    };
}
