const Dashboard = () => import("@songfinch/customer/views/dashboard/Dashboard");
const Profile = () => import("@songfinch/customer/views/dashboard/subpages/Profile");
const OrderHistory = () => import("@songfinch/customer/views/dashboard/subpages/OrderHistory");
const CreditBalance = () => import("@songfinch/customer/views/dashboard/subpages/CreditBalance");
const ReferralProgram = () => import("@songfinch/customer/views/dashboard/subpages/ReferralProgram");

export default [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {requireAuth: true},
        redirect: to => ({name: "Profile", query: to.query}),
        children: [
            {
                path: "profile",
                name: "Profile",
                component: Profile,
                meta: {enableZenDesk: true},
            },
            {
                path: "order-history",
                name: "OrderHistory",
                component: OrderHistory,
            },
            {
                path: "credit-balance",
                name: "CreditBalance",
                component: CreditBalance,
            },
            {
                path: "referral-program",
                name: "ReferralProgram",
                component: ReferralProgram,
            },
        ]
    }
];
