import app from "@songfinch/customer/app.js";
import axios from "axios";
import {sortBy, map} from "lodash-es";
import SharePopup from "../components/dashboard/SharePopup";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";

export default {
    namespaced: true,
    state: {
        reloadingApp: false,
        geoData: null,
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        isIOS: (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream),
        isMobileResolution: false,
        cloudanoryWidgetSettings: {
            sources: [
                "local",
                "url",
                "camera"
            ],
            styles: {
                palette: {
                    window: "#FFFFFF",
                    windowBorder: "#54524C",
                    tabIcon: "#83817B",
                    menuIcons: "#83817B",
                    textDark: "#83817B",
                    textLight: "#FFFFFF",
                    link: "#F64D1C",
                    action: "#F64D1C",
                    inactiveTabIcon: "#A7A5A3",
                    error: "#F64D1C",
                    inProgress: "#F64D1C",
                    complete: "#F64D1C",
                    sourceBg: "#F3F1EB",
                },
                fonts: {
                    default: null,
                    "National-Book, sans-serif": {
                        url: "https://media.songfinch.com/raw/upload/v1630661873/fonts/National-Book.otf",
                        active: true,
                    },
                }
            }
        }
    },
    getters: {
        countriesOptions(state) {
            const temp = map(state.geoData?.countries, (v, k) => ({id: k, text: v}));
            return sortBy(temp, o => o.id === "US" ? "A" : o.text);
        },
        statesOptions: state => map(state.geoData?.states, (v, k) => ({id: k, text: v})),
    },
    mutations: {
        setResolutionsFlags(state) {
            state.isMobileResolution = window.innerWidth < 768;
        },
        setReloadingApp(state, val) {
            state.reloadingApp = val;
        },
        clearLocalStorage() {
            localStorage.removeItem("sf_song_data");
            localStorage.removeItem("sf_cart");
            localStorage.removeItem("sf_fe_data_version");
            localStorage.removeItem("sf_addresses");
        },
        showModal(state, payload) {
            app.config.globalProperties.$sfModal.show(payload);
        },
        closeModal() {
            app.config.globalProperties.$swal.close();
        },
        showSlideModal(state, payload) { //slide center-end for desktop and bottom for tablet and mobile
            payload.swalSettings = {
                ...payload.swalSettings,
                width: payload.swalSettings?.width ?? 775
            };
            payload.slide = "center-end";
            payload.responsive = {
                1199: {
                    swalSettings: {width: "100%"},
                    slide: "bottom"
                },
            };
            this.commit("shared/showModal", payload);
        },
        warningMsg(state, msg) {
            let settings = {
                icon: "warning",
            };
            if (typeof msg === "object") {
                settings = {...settings, ...msg};
            } else {
                settings.html = msg ? `<div class="h5">${msg}</div>` : "Error!";
            }
            app.config.globalProperties.$swal(settings);
        },
        showSharePopup(state, props) {
            this.commit("shared/showModal", {
                component: SharePopup,
                props,
                swalSettings: {
                    width: 400
                }
            });
        },
        setHasInteractedWithSongfinch() {
            localStorage.setItem("hasInteractedWithSongfinch", "true");
        },
    },
    actions: {
        async confirmationModal(state, payload) {
            let settings = {
                width: 375,
                showCloseButton: false,
                showCancelButton: true,
                customClass: {
                    container: "small confirmation",
                    confirmButton: "mkButton short noLoading",
                    cancelButton: "mkButton short beige noLoading",
                    actions: "@mb-8 @mt-0 @px-2 @flex @w-full @gap-4 @max-w-300 @flex-row-reverse"
                },
                confirmButtonText: "Yes, I'm sure!",
                cancelButtonText: "cancel",
            };
            if (typeof payload === "object") {
                settings = {...settings, ...payload};
            } else {
                settings.html = "<div class='p3'>" + payload + "</div>";
            }
            const res = await app.config.globalProperties.$swal(settings);
            return res.isConfirmed;
        },
        async loadGeoData({state}) {
            const res = await axios.get("/settings/geo_data");
            delete (res.data.countries.COUNTRY_ISO_CODE);
            state.geoData = res.data;
        },
        async sharedCopyLink(store, link) {
            try {
                await navigator.clipboard.writeText(link);
                $toastMsg("Copied!");
            } catch (e) {
                $toastMsg("Your browser can't copy the link; please copy this link manually.");
            }
        }
    }
};
