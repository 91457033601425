export default {
    install(app, {features}) {
        // Allow mocking frontend feature flags with query string for dev + staging
        if (window.appSettings.env !== "production") {
            const params = new URLSearchParams(window.location.search);

            for (const key of params.keys()) {
                if (key.indexOf("feature_") === 0) {
                    const flag = key.slice("feature_".length);
                    const value = params.get(key) !== "false" && params.get(key) !== "0";
                    features[flag] = value;
                }
            }
        }

        app.config.globalProperties.$flipper = {
            features,
            values: Object.keys(features).map((name) => `${name}_${features[name] ? "on" : "off"}`)
        };

        app.provide("$flipper", app.config.globalProperties.$flipper);
    }
};
