<template>
    <div class="productDetails @container @text-left">
        <div v-if="currentProduct" class="@grid @grid-cols-1 tablet:@grid-cols-2 @gap-x-8 desktop:@gap-x-16">
            <ProductTitle class="tablet:@col-span-2 tablet:@hidden @mb-6" :product="currentProduct"/>
            <div class="@relative">
                <ProductImageSlider :key="currentProduct.id" class="@mb-6" :product="currentProduct"/>
            </div>
            <div>
                <form class="@max-w-475 @mx-0" @submit.prevent="addToCart">
                    <ProductTitle class="@hidden tablet:@block" :class="{'@mb-6': isNotGiftCard}" :product="currentProduct"/>
                    <div>
                        <div v-if="productsOptions" class="@mb-4">
                            <div class="l8 @mb-2" v-text="variantLabel"/>
                            <MkSelect v-model="currentProductSlug" :options="productsOptions"/>
                        </div>
                        <SongSelector v-if="isNotGiftCard && !bundleContainsCoreProduct" :product="currentProduct" @story-changed="error = ''"/>
                        <ArtistGratuityFields v-if="isArtistGratuity" v-model="currentProduct.price" class="@mt-4"/>
                    </div>
                    <div class="@pt-4 text-danger">
                        <SlideUpDown :model-value="error"/>
                    </div>
                    <button class="mkButton tall @w-full @mt-4 tablet:@mt-4 @mb-10 disabledIfInvalid">
                        ADD TO CART
                    </button>
                    <ProductDescription class="@hidden desktop:@block" :product="currentProduct"/>
                </form>
            </div>
            <ProductDescription class="tablet:@col-span-2 desktop:@hidden" :product="currentProduct"/>
        </div>
    </div>
</template>

<script>
    import ProductImageSlider from "./ProductImageSlider";
    import SongSelector from "./SongSelector";
    import ProductDescription from "./ProductDescription";
    import ArtistGratuityFields from "./ArtistGratuityFields";
    import ProductTitle from "./ProductTitle";
    import MkSelect from "@songfinch/customer/components/shared/MkSelect";
    import SlideUpDown from "@songfinch/shared/components/SlideUpDown";
    import router from "@songfinch/customer/router";
    import {excludedInstantAddons, loadSingleProduct} from "@songfinch/customer/composables/useProduct";
    import {size, map, forEach, omit} from "lodash-es";

    export default {
        name: "ProductDetails",
        components: {ProductTitle, ArtistGratuityFields, ProductDescription, SongSelector, ProductImageSlider, MkSelect, SlideUpDown},
        props: {
            productSlug: {
                type: String,
                default: ""
            },
            productProps: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                currentProductSlug: String(this.productSlug),
                variantsList: {},
                error: "",
                coreProductsName: ["instant-product", "personalized-song"],
                loadedProduct: null
            };
        },
        computed: {
            productsOptions() {
                if (size(this.variantsList) > 1) {
                    return map(this.variantsList, v => ({id: v.name, text: (v.extra_info?.variant_name || v.title)}));
                }
                return false;
            },
            currentProduct() {
                let current = this.variantsList[this.currentProductSlug];
                if (current && this.$route.name === "ProductSingle") {
                    document.title = current.title;
                }
                current &&= {...current, ...this.productProps};
                return current || null;
            },
            variantLabel() {
                return this.loadedProduct.extra_info?.variant_label || "Options";
            },
            selectedStory() {
                return this.$store.state.store.selectedStory;
            },
            cart() {
                return this.$store.state.cart.cart;
            },
            isNotGiftCard() {
                return this.currentProduct.name !== "gift-card";
            },
            bundleContainsCoreProduct() {
                return this.currentProduct.product_type === "bundle" &&
                    this.currentProduct?.bundled_products.some((item) => this.coreProductsName.includes(item.name));
            },
            isArtistGratuity() {
                return this.currentProduct.name === "artist-gratuity";
            }
        },
        async created() {
            try {
                this.loadedProduct = await loadSingleProduct({productSlug: this.productSlug, getWithParent: true});
            } catch (e) {
                await router.push({name: "404"});
                return  false;
            }

            if (!this.loadedProduct) return;
            this.assignProductToList(this.loadedProduct);
            forEach(this.loadedProduct.children, this.assignProductToList);
        },
        methods: {
            async addToCart() {
                this.error = "";
                if (!this.currentProduct) {
                    return false;
                }
                if (
                    this.currentProduct?.extra_info?.one_per_story
                    && (this.selectedStory?.products?.find(id => this.currentProduct.id === id)
                        || this.cart.findProductByName(this.currentProduct.name))
                ) {
                    return this.error = `You can have only one ${this.currentProduct.title} per song`;
                }
                if (this.currentProduct.name === "extra-verse" && this.selectedStory && !["shipped"].includes(this.selectedStory.status)) {
                    return this.error = `Extra verses can only be purchased after the song is complete`;
                }
                if (this.selectedStory?.is_instant && excludedInstantAddons.includes(this.currentProduct.name)) {
                    return this.error = `${this.currentProduct.title} cannot be purchased for a Jukebox song.`;
                }

                const productParam = {};
                if (this.selectedStory) {
                    productParam.parent_story_id = this.selectedStory.id;
                    productParam.owner_id = this.selectedStory.owner_id;
                }

                await this.$store.dispatch("cart/addProduct", {
                    product: {...this.currentProduct, ...productParam},
                });
                this.$swal.close();
            },
            assignProductToList(p) {
                this.variantsList[p.name] = {...omit(p, "children")};
            }
        }

    };
</script>

<style scoped>
    .productDetails {
        :deep(.productDescription) {
            p {
                margin-bottom: 10px;
            }

            li {
                margin-bottom: 3px;
            }
        }
    }
</style>
