<template>
    <div class="@pt-10 @pb-16 @px-5">
        <h5 class="@mb-6" v-html="title"/>
        <a
            v-for="(item, index) in links"
            :key="index"
            target="_blank"
            class="@flex @justify-between @items-center @py-2.5 @border-b border-bottom"
            :class="{'@border-t': !index}"
            :href="item.url"
            @click="clicked(item, $event)"
        >
            <span class="l3">{{item.title}}</span>
            <img :src="item.icon" :alt="item.title" width="40">
        </a>
    </div>
</template>

<script>
    import copyLink from "@songfinch/customer/assets/images/share_icons/copy_link.png";
    import emailIcon from "@songfinch/customer/assets/images/share_icons/email.png";
    import facebookIcon from "@songfinch/customer/assets/images/share_icons/facebook.png";
    import twitterIcon from "@songfinch/customer/assets/images/share_icons/x.png";
    import messengerIcon from "@songfinch/customer/assets/images/share_icons/messenger.png";
    import whatsappIcon from "@songfinch/customer/assets/images/share_icons/whatsapp.png";
    import smsIcon from "@songfinch/customer/assets/images/share_icons/sms.png";
    export default {
        name: "SharePopup",
        props: {
            showCopyAction: Boolean,
            text: {required: true, type: String},
            title: {type: String, default: ""},
            link: {required: true, type: String},
            subjectText: {type: String, default: "Check out Songfinch!"},
            callback: {type: Function, default: null},
            showMobile: Boolean
        },
        data() {
            return {
                links: [
                    {
                        linkCallback: () => {
                            this.$store.dispatch("shared/sharedCopyLink", this.link);
                        },
                        icon: copyLink,
                        title: "Copy link",
                        copyAction: true
                    },
                    {
                        url: `mailto:?subject=${this.subjectText}&body=${this.text}`,
                        icon: emailIcon,
                        title: "Email"
                    },
                    {
                        url: `https://www.facebook.com/sharer.php?u=${this.link}`,
                        icon: facebookIcon,
                        title: "Facebook"
                    },
                    {
                        url: `https://x.com/intent/tweet?text=${this.text}`,
                        icon: twitterIcon,
                        title: "Twitter"
                    },
                    {
                        url: `fb-messenger://share/?link=${this.link}`,
                        icon: messengerIcon,
                        title: "Messenger",
                        mobileOnly: true
                    },
                    {
                        url: `whatsapp://send?text=${this.text}`,
                        icon: whatsappIcon,
                        title: "WhatsApp",
                        mobileOnly: true
                    },
                    {
                        url: `sms:${this.$store?.state?.shared?.isIOS ? "&" : "?"}body=${this.text}`,
                        icon: smsIcon,
                        title: "Messages",
                        mobileOnly: true
                    }
                ]
            };
        },
        created() {
            if (!this.$store?.state?.shared?.isMobile && !this.showMobile) {
                this.links = this.links.filter(l => !l.mobileOnly);
            }

            if (!this.showCopyAction || !navigator.clipboard) {
                this.links = this.links.filter(l => !l.copyAction);
            }
        },
        methods: {
            clicked(obj, e) {
                if (obj.linkCallback) {
                    e.preventDefault();
                    obj.linkCallback();
                }

                this.callback?.(obj);
            }
        }
    };
</script>
