const Store = () => import("@songfinch/customer/views/store/Store");
const ProductSingle = () => import("@songfinch/customer/views/store/ProductSingle");

export default [
    {
        path: "/products/:product_id",
        name: "ProductSingle",
        component: ProductSingle,
        meta: {CMSData: "productSingle", skipRedirect: true},
    },
    {
        path: "/store",
        name: "Store",
        component: Store,
        meta: {enableZenDesk: true},
    }
];
