import app from "@songfinch/customer/app.js";
import "./helpers/init_core";

import router from "./router";
import store from "./store";

import "./helpers/sentry";
import "./listeners/events";
import "./helpers/attentive";
import "./helpers/zendesk";

import "./helpers/check_version";
import "./helpers/axios_api_init";
import "./helpers/plugins_init";
//import "./helpers/contest_init";
import "./helpers/global_vars";
import "./helpers/check_submission";
import {initUUID} from "@songfinch/shared/helpers/uuid";

import loadLocales from "./locales";

import "vue2-animate/dist/vue2-animate.min.css";

import {initGtmData} from "@songfinch/customer/helpers/gtm_song_data_helper";
import useFlipper from "@songfinch/shared/composables/useFlipper";

import page_loaded from "./mixins/page_loaded";

app.mixin(page_loaded);

//Inits before Vue launched
setTimeout(() => initGtmData()); //Temp solution to run initGtmData async to prevent breaking app if any errors
store.commit("songBuilder/loadLocalData");
// We want it keep it here for pagespeed increase
store.commit("cms/initNavs");
store.commit("cart/initCart");
store.dispatch("cart/initAffiliate");
store.commit("auth/setUser", window.appSettings.user);
store.commit("auth/checkForReferralCode");

const initVue = async () => {
    const el = document.body.appendChild(document.createElement("app"));
    const {initFlipper} = useFlipper();
    initFlipper();
    await initUUID();
    app.use(await loadLocales());
    app.use(router); //all async/awaits should be before router, otherwise modals routes won't showup on load
    app.use(store);
    app.mount(el);
};

initVue();
