export default {
    errors: {
        sections_not_complete: "Complete all sections above before proceeding.",
        payment_failed: "The payment failed. Please try again or use a different payment method"
    },
    buttons: {
        continue_to: "continue to",
        complete_purchase: "complete purchase",
    },
    sections: {
        account: "Account email",
        billing: "Billing address",
        shipping: "Shipping address",
        payments: "Payment",
    }
};
