import shared from "./shared";
import checkout from "./checkout";
import story from "./story";
import songbuilder from "./songbuilder";
import playlists from "./playlists";
import home from "./home";
import orderHistory from "./orderHistory";
import instantBuilder from "./instantBuilder";

export default {
    shared,
    checkout,
    story,
    playlists,
    songbuilder,
    home,
    orderHistory,
    instantBuilder
};
