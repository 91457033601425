<template>
    <form :class="{isSubmitted}" class="@text-center desktop:@text-left cmsContent p3 @pt-10 desktop:@pt-12 @pb-20 @px-5 desktop:@px-16" @submit.prevent="submit">
        <div class="@max-w-475 @m-0">
            <h3 class="@mb-4" v-html="cms.title"/>
            <p class="!@mb-6" v-html="cms.subtitle"/>
            <div v-for="(i, n) in 4" :key="i" class="l8 @text-left form-group @mb-5">
                detail {{i}}
                <input
                    v-model="formData[n]"
                    class="form-control"
                    :required="i === 1"
                    maxlength="100"
                >
            </div>
            <div class="l3 @my-6" v-html="cms.notes"/>
            <MkCheckbox class="@mb-4 @text-sfc-dark-grey" required :text="cms.agreement"/>
            <button class="mkButton xl @w-full tall" @click="isSubmitted = true">
                ADD TO CART
            </button>
        </div>
    </form>
</template>

<script>
    import MkCheckbox from "@songfinch/shared/components/MkCheckbox";

    export default {
        name: "ExtraVersePopup",
        components: {MkCheckbox},
        props: {
            product: {type: Object, required: true}
        },
        emits: ["submit"],
        data() {
            return {
                isSubmitted: false,
                formData: new Array(4).fill(""),
                cms: {},
            };
        },
        async created() {
            this.cms = await this.$store.dispatch("cms/getField", {group: "modals", slug: "extraVerse"});
        },
        methods: {
            submit() {
                const your_request = this.formData
                    .filter(val => val)
                    .join("\r\n-------------------\r\n");

                this.$emit("submit", {
                    ...this.product,
                    additional_info: {your_request}
                });
                this.$swal.close();
            }
        }
    };
</script>

<style>
    @import "@songfinch/customer/stylesheets/includes/pages/_cms-content.pcss";
</style>
