<template>
    <div class="imageUploaderPopup">
        <div class="@container @min-h-screen @flex @justify-center @py-20">
            <div class="@grid @grid-cols-12 @items-center !@m-auto @max-w-1000 @w-full">
                <div class="@col-span-12 @px-4 tablet-lg:@col-span-6 @text-center tablet-lg:@text-left @flex">
                    <div class="contentBox p3">
                        <div v-if="cmsData.content" v-html="cmsData.content"/>
                        <div v-else>
                            <h2 v-html="product.title"/>
                            <p>To achieve the best results possible, we recommend using high resolution photos. We currently support jpg, png, or heic files.</p>
                        </div>
                        <button class="mkButton !@hidden tablet-lg:!@inline-block noLoading !@min-w-[275px] @mt-8 tall" :disabled="isDisabled" @click="addProduct">
                            {{update ? "Update" : "ADD TO CART"}}
                        </button>
                    </div>
                </div>
                <div class="@col-span-12 @px-4 tablet-lg:@col-span-6">
                    <div class="imageBox @mt-10 tablet-lg:@mt-0" :class="{'@inline-block': cmsData.image}">
                        <img v-if="cmsData.image" class="productImage" :src="$cld.url(cmsData.image)" alt="product image">
                        <div
                            class="uploadContent"
                            :class="{error}"
                            :style="[coordinates, {backgroundImage: $cld.bgUrl(cover_public_id)}]"
                        >
                            <div v-if="error" class="p4 @text-sfc-white @px-4 tablet:@px-10">
                                <img :src="warningIcon" class="@mb-4" alt="error" style="width: 28px;">
                                <div v-html="error"/>
                            </div>
                            <div class="@text-center" :class="{'@mt-auto': (cover_public_id && !error)}">
                                <button class="mkButton short @my-5 noMinWidth" :class="{beige: cover_public_id}" @click.prevent="openUploader">
                                    {{error ? "upload new photo" : cover_public_id ? 'edit' : 'upload a photo'}}
                                </button>
                            </div>
                        </div>
                        <img v-if="cmsData.image" :src="shadow" class="boxShadows @none small:@inline-block" alt="shadow">
                        <img v-if="cmsData.image" :src="shadowMobile" class="boxShadows mob small:@hidden" alt="shadow">
                    </div>
                    <div>
                        <button
                            class="mkButton tablet-lg:!@hidden !@inline-block fullMob noLoading !@min-w-[275px] @my-12 tall"
                            :disabled="isDisabled"
                            @click="addProduct"
                        >
                            {{update ? "Update" : "ADD TO CART"}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import is_image_exists from "@songfinch/customer/helpers/is_image_exists";
    import error_handler from "@songfinch/shared/helpers/axios_error_handler";
    import warningIcon from "@songfinch/customer/assets/images/warning.png";
    import shadow from "@songfinch/customer/assets/images/product_shadow.png";
    import shadowMobile from "@songfinch/customer/assets/images/product_shadow_mob.png";
    import useLoadScript from "@songfinch/customer/composables/useLoadScript";
    import {find} from "lodash-es";

    export default {
        name: "ImageUploaderPopup",
        props: {
            product: {type: Object, required: true},
        },
        emits: ["submit"],
        data() {
            return {
                count: 0,
                cms: null,
                cover_public_id: "",
                error: "",
                warningIcon,
                shadow,
                shadowMobile,
                inProgress: false,
                upload_preset: "hrlccu7o",
                widget: null,
                isDisabled: true
            };
        },
        computed: {
            update() {
                return this.product.update;
            },
            cmsData() {
                return find(this.cms?.list, el => el.product_name === this.product.name) || {};
            },
            coordinates() {
                return this.cmsData.coordinates || {width: "100%", transform: "none", maxWidth: "100%", paddingTop: "100%", position: "static"};
            }
        },
        watch: {
            async cover_public_id(val) {
                this.isDisabled = true;
                if (val) {
                    try {
                        await is_image_exists(this.$cld.url(val));
                        this.isDisabled = false;
                    } catch (e) {
                        this.error = "Unable to load image! Please try again or try a different photo.";
                    }
                }
            }
        },
        async created() {
            this.cover_public_id = this.product.cover_public_id;
            this.cms = await this.$store.dispatch("cms/getField", {group: "modals", slug: "productImageUploader"});
        },
        beforeUnmount() {
            this.widget?.destroy();
        },
        methods: {
            async openUploader() {
                if (this.inProgress) return;
                this.inProgress = true;
                this.error = "";

                await useLoadScript("https://widget.cloudinary.com/v2.0/global/all.js");
                this.widget ??= window.cloudinary.createUploadWidget(
                    {
                        cloud_name: "songfinch",
                        maxFileSize: 20000000, // 20mb
                        cropping: true,
                        croppingAspectRatio: 1,
                        showSkipCropButton: false,
                        croppingShowDimensions: true,
                        clientAllowedFormats: ["jpg", "jpeg", "png", "tiff", "webp", "heic"],
                        multiple: false,
                        upload_preset: this.upload_preset,
                        minImageWidth: 500,
                        minImageHeight: 500,
                        prepareUploadParams: async (cb, params) => {
                            try {
                                const res = await axios.get(`cloudinary/get_signature`, {
                                    params: {
                                        timestamp: params.timestamp,
                                        custom_coordinates: params.custom_coordinates,
                                        upload_preset: this.upload_preset,
                                    }
                                });
                                cb({
                                    apiKey: window.appSettings.cloudinary_api_key,
                                    signatureTimestamp: res.data.timestamp,
                                    signature: res.data.signature,
                                    folder: `${this.$env}/temp`,
                                });
                            } catch (e) {
                                this.error = error_handler(e).error;
                                this.inProgress = false;
                            }
                        },
                        ...this.$store.state.shared.cloudanoryWidgetSettings
                    },
                    (error, result) => {
                        if (result?.event === "success") {
                            this.cover_public_id = result.info?.public_id;
                            this.widget.close();
                        } else if (error) {
                            this.error = error?.status || error_handler(error).error;
                            this.widget.close();
                        }
                        this.inProgress = false;
                    });
                this.widget.open();
            },
            addProduct() {
                this.$emit("submit", {
                    ...this.product,
                    cover_public_id: this.cover_public_id,
                    expire: this.$dayjs().add(7, "day")
                });
                this.$swal.close();
            }
        },
    };
</script>

<style scoped>
    .imageUploaderPopup {
        overflow-y: auto;

        .contentBox {
            max-width: 575px;
            margin: auto;
            @screen tablet-v {
                max-width: 375px;
                margin-left: 0;
            }
        }

        .imageBox {
            position: relative;

            .productImage {
                max-width: 100%;
                position: relative;
                z-index: 2;
                pointer-events: none;
            }


            .uploadContent {
                position: absolute;
                transform: translate(-50%, -50%);
                background: var(--sfc-dark-beige);
                background-position: center;
                background-size: cover;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-self: center;

                &.error {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        background-color: var(--sfc-black);
                    }
                }

                > * {
                    position: relative;
                    z-index: 2;
                }
            }

            .boxShadows {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 45%);
                bottom: 0;
                width: 143.5%;

                &.mob {
                    width: 112.5%;
                }
            }
        }
    }
</style>
