import {readonly, ref} from "vue";
import useLoadScript from "@songfinch/customer/composables/useLoadScript";
import store from "@songfinch/customer/store";

const stripe = ref<unknown>();

export async function loadStripe() {
    if (stripe.value) return;
    await useLoadScript("https://js.stripe.com/v3");
    stripe.value = new window.Stripe(window.appSettings.stripe);
}

type ProcessStripePaymentParams = {
    stripeElements: unknown,
    returnUrl: string,
    stripeClientSecret?: string
}

export async function processStripePayment({stripeElements, returnUrl, stripeClientSecret}: ProcessStripePaymentParams) {
    stripeClientSecret ||= store.state.cart.cart.stripe_client_secret;
    if (!stripeClientSecret) throw Error("missing stripe stripe client secret");
    if (!stripeElements) throw Error("missing stripe elements");
    if (!returnUrl) throw Error("missing returnUrl");
    const {error: submitError} = await stripeElements.submit();

    if (submitError) {
        throw submitError;
    }

    const payment = await stripe.value.confirmPayment({
        elements: stripeElements,
        clientSecret: stripeClientSecret,
        confirmParams: {
            return_url: returnUrl,
        },
    });

    return payment;
}

const data = {
    stripe: readonly(stripe),
};

export default function useStripe() {
    return data;
}
