<template>
    <header v-if="data" class="@py-4">
        <div class="@container @flex @items-center @z-[2] @relative @w-full headerContainer">
            <router-link :to="{name: 'Home'}" class="@mr-auto" aria-label="home page">
                <Logo fill="var(--sfc-black)" width="143" height="39"/>
            </router-link>
            <!--Desktop Nav-->
            <div class="@hidden tablet-lg:@flex @items-center mrChild30" data-menu>
                <div v-for="(item, i) in data.new_menu" :key="i">
                    <div v-if="item.sub_nav" class="dropDown" data-menu-sub_nav>
                        <span class="l3" v-text="item.label"/>
                        <div v-if="item.sub_nav" class="dropDownList left">
                            <a v-for="(sub_item, index) in item.sub_nav" :key="index" class="p3" :href="sub_item.link">
                                {{sub_item.label}}
                            </a>
                        </div>
                    </div>
                    <a v-else :href="item.link" class="l3" data-menu-link v-text="item.label"/>
                </div>
                <div class="vline" data-menu-divider/>
                <span class="p3 @relative">
                    <div class="dropDown @cursor-pointer">
                        <router-link v-if="!user" :to="{name: 'Login'}">Login</router-link>
                        <span v-else>
                            {{user.first_name ? `Hi, ${user.first_name}` : "Account"}}
                            <span class="dropIcon @ml-1.5" :style="{backgroundImage: `url(${chevronDrop})`}"/>
                            <div class="dropDownList">
                                <a v-if="user.role === 'admin'" class="skipVueRouting" href="/admin">Admin</a>
                                <a v-else-if="user.role === 'artist'" class="skipVueRouting" href="/artist-admin">Artist
                                    Dashboard</a>
                                <router-link :to="{name: 'Profile'}">Account</router-link>
                                <router-link :to="{name: 'OrderHistory'}">Order history</router-link>
                                <router-link :to="{name: 'CreditBalance'}">
                                    Credit balance <div v-if="creditBalance" class="p4 sub">You have a {{creditBalance}}
                                        balance</div>
                                </router-link>
                                <router-link :to="{name: 'ReferralProgram'}">Referrals</router-link>
                                <a href="#" @click.prevent="$store.dispatch('auth/logout')">Logout</a>
                            </div>
                        </span>
                    </div>
                    <LearnTooltip tool-class="!@w-[375px] !@text-sfc-white" flag-name="ag_navigation" :save-in-local-storage="false">
                        When you’re ready to complete your Digital Greeting card, you can access it from your
                        <router-link class="@inline-block" :to="{name: 'OrderHistory'}"><u>Order
                            history</u></router-link>.
                    </LearnTooltip>
                </span>
                <router-link class="cartIcon" :to="{name: 'Cart'}" aria-label="go to cart">
                    <img :src="cartIcon" alt="cart">
                    <span v-if="cartLength" class="amount">{{cartLength}}</span>
                </router-link>
            </div>

            <!--Mobile Menu-->
            <div class="@flex @items-center mrChild30">
                <div v-if="!hasOriginalSong" class="vline @hidden tablet-lg:@block" data-menu-divider/>
                <StartSongButton
                    class="mkButton !@hidden tablet:!@block"
                    :class="{dark: abDarkButton}"
                    data-cy="start-song-builder-navigation"
                />
                <div v-if="!hasOriginalSong" class="vline @hidden tablet:@block tablet-lg:!@hidden" data-menu-divider/>
                <MobileNav right close-icon class="tablet-lg:@hidden">
                    <a v-for="(item, i) in data.mobile_menu" :key="i" :href="item.link" class="h3 @text-nowrap">
                        {{item.label}}
                    </a>
                    <router-link class="h3 @text-nowrap" :to="{name: 'Cart'}">
                        Cart <span v-if="cartLength" class="p1 @ml-2">({{cartLength}})</span>
                    </router-link>
                    <div class="@border-b @border-sfc-dark-beige/[.15] @max-w-185 @my-5 !@p-0 @w-full"/>
                    <router-link v-if="!user" class="p1" :to="{name: 'Login'}">
                        Login
                    </router-link>
                    <template v-else>
                        <a v-if="user.role === 'admin'" class="p1 skipVueRouting" href="/admin">Admin</a>
                        <a v-else-if="user.role === 'artist'" class="p1 skipVueRouting" href="/artist-admin">Artist
                            Dashboard</a>
                        <router-link class="p1" :to="{name: 'Profile'}">
                            Account
                        </router-link>
                        <router-link class="p1" :to="{name: 'OrderHistory'}">
                            Order history
                        </router-link>
                        <router-link class="p1" :to="{name: 'CreditBalance'}">
                            Credit balance <div v-if="creditBalance" class="@w-full p4 @text-sfc-light-grey">
                                You have a {{creditBalance}} balance
                            </div>
                        </router-link>
                        <router-link class="p1" :to="{name: 'ReferralProgram'}">
                            Referrals
                        </router-link>
                        <a href="#" class="p1" @click.prevent="$store.dispatch('auth/logout')">Logout</a>
                    </template>
                    <div class="!@px-5 @mt-auto tablet:@hidden @w-full">
                        <StartSongButton class="mkButton fullMob" :class="{dark: abDarkButton}"/>
                    </div>
                </MobileNav>
            </div>
        </div>
    </header>
</template>

<script>
    import Logo from "@songfinch/customer/assets/images/Logo";
    import MobileNav from "@songfinch/customer/components/global/MobileNav";
    import LearnTooltip from "@songfinch/shared/components/LearnTooltip.vue";
    import cartIcon from "@songfinch/customer/assets/images/cart.svg";
    import chevronDrop from "@songfinch/customer/assets/images/chevron_down.svg";
    import StartSongButton from "@songfinch/customer/components/global/StartSongButton.vue";

    export default {
        name: "Navigation",
        components: {Logo, MobileNav, LearnTooltip, StartSongButton},
        data() {
            return {
                tempWatch: true,
                cartIcon,
                chevronDrop
            };
        },
        computed: {
            data() {
                return this.$store.state.cms.navigation;
            },
            cartLength() {
                return this.$store.state.cart.cart.length();
            },
            hasOriginalSong() {
                return this.$store.state.cart.cart.hasOriginalSong;
            },
            user() {
                return this.$store.state.auth.user;
            },
            creditBalance() {
                return this.user?.total_credits ? this.$sfPriceInDollars(this.user.total_credits) : false;
            },
            abDarkButton() {
                return this.$flipper.features.ab_dark_button;
            }
        }
    };
</script>

<style scoped>
    @import "@songfinch/customer/stylesheets/includes/mixins/index.pcss";

    header {
        position: sticky;
        z-index: var(--zix-header);
        top: 0;
        transition: color 0.5s, margin 0.5s, z-index 0s linear 1s;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.95;
            background-color: var(--sfc-beige);
            transition: background-color 0.5s linear 0.5s;
        }

        &:after {
            content: "";
            position: fixed;
            width: 0;
            height: 0;
            background: var(--sfc-black);
            opacity: 0;
            top: 0;
            left: 0;
            transition: opacity 0.3s;
        }

        .mrChild30>* {
            margin-left: 20px;

            @screen desktop {
                margin-left: 30px;
            }
        }

        .dropDown {
            position: relative;
            padding: 5px 0;
            cursor: pointer;

            .dropDownList {
                position: absolute;
                background: var(--sfc-white);
                right: 0;
                top: 100%;
                overflow: hidden;
                height: 0;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2), 0 2px 20px rgba(0, 0, 0, 0.02);
                border-radius: 6px;
                opacity: 0;
                padding: 0;
                transition: opacity 0.2s;

                &.left {
                    left: -30px;
                    right: auto;
                }

                a {
                    display: block;
                    padding-bottom: 12px;
                    cursor: pointer;
                    white-space: nowrap;
                    min-width: 165px;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    .sub {
                        transition: all 0.3s;
                        color: var(--sfc-medium-grey);
                    }

                    &:hover .sub {
                        color: var(--sfc-red);
                    }
                }
            }

            &:hover,
            &.active,
            &:focus-within {
                .dropDownList {
                    padding: 22px 20px 33px;
                    opacity: 1;
                    height: auto;
                }
            }

            .dropIcon {
                width: 14px;
                height: 14px;
                display: inline-block;
            }
        }

        .cartIcon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;

            img {
                margin-left: 1px;
            }

            .amount {
                position: absolute;
                color: var(--sfc-white);
                @include font(Helvetica, 12px, 12px, 700);
                right: 11%;
                top: 11%;
                transform: translate(50%, -50%);
                background-color: var(--sfc-red);
                min-width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                padding: 0 5px;
            }
        }

        .vline {
            width: 1px;
            height: 34px;
            background-color: var(--sfc-dark-beige);
        }
    }
</style>
